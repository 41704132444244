<template>
	<div class="container-fluid">
		<div class="row shadow py-1 mb-3 bg-white rounded">
			<div class="header mb-3 mt-1">
				<div class="col-md-12 d-flex justify-content-center">
					<div class="image align-self-center">
						<img src="https://yt3.ggpht.com/ytc/AAUvwnjS6ydKEHpruDqYSeCLJDane9ol18wjXnidgJ9mhA=s900-c-k-c0x00ffffff-no-rj" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style>
.header {
	height: 40px;
	text-align: center;
	background: #ffff;
	font-size: 30px;
}
.image img {
	height: 50px;
	width: 50px;
	border-radius: 15px;
}
</style>
