<template>
	<div class="home">
		<div>
			<Navbar />
		</div>
		<div class="container">
			<div class="row">
				<div class="card rounded col-md-4 offset-md-4 my-4 px-0 shadow-lg">
					<div class="card-body p-0 rounded">
						<div class="d-flex align-items-center p-3 mb-0">
							<div class="flex-shrink-0">
								<div class="rounded-image">
									<img :src="'https://lux-we.essap.xyz/object/cbas/images/avatar/' + this.discussion.user.username + '.jpg'" />
								</div>
							</div>
							<div class="flex-column">
								<div class="flex-grow-1 ms-3 h4 mb-1">
									<b>{{ this.discussion.user.username }}</b>
								</div>
								<div class="flex-grow-1 ms-3 h6">{{ formatTimeStamp(this.discussion.timestamps.created) }}</div>
							</div>
						</div>


						<div class="mt-0 p-3 h5 mb-1 lh-base">
							<div v-if="this.discussion.type == 'discussion' || 'question'" >
								{{ this.discussion.content }}
							</div>
						</div>

						<div class="flex-grow-1 ms-3 h6 p-1">
							<h6 v-if="this.discussion.type == 'question'">
								<span  class="badge bg-warning text-dark justify-content-center " >
									ප්‍රශ්න
								</span>
							</h6>
							<h6 v-else>
								<span  class="badge bg-success justify-content-center" >
									සාකච්ඡා
								</span>
							</h6>

						</div>
					</div>


				</div>
			</div>

		</div>

		<div class="container">
				<div class="row">
					<div class="play col-md-4 d-flex offset-md-4 justify-content-center p-3">
						<div class="badge p-1">
							<a href="https://play.google.com/store/apps/details?id=com.chanuxbro"><img src="../../assets/images/google.png" class="img-fluid p-2 mt-3"/></a>
						</div>
						<div class="badge p-1">
							<a href="https://apps.apple.com/lk/app/chanux-bro/id1560152155"><img src="../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" class="img-fluid p-2 mt-3"/></a>
						</div>

					</div>
				</div>
		</div>

	</div>
</template>


<script>
import moment from "moment"
import Navbar from "../../components/Navbar";


export default {
	name: "Discussion",
	components: {
		Navbar,

	},

	data: () => ({
		discussion: {},

	}),

	mounted() {
		/**
		 * Fetch data from the origin API and store in the local
		 * data values for future usage.
		 */
		fetch("https://api.cbas.xyz/v1.0/discussion/" + this.$route.params.id)
			.then((Response) => Response.json())
			.then((Response) => {
				this.discussion = Response.data.discussion;
			});
	},

	methods: {
		formatTimeStamp(timestamp) {
			return moment.unix(timestamp).fromNow();
		}
	}
};
</script>

<style scoped>
.post {
	margin-top: 50px;
	border: 1px solid rgb(226, 218, 218);
	height: 500px;
	-webkit-box-shadow: 3px 3px 5px 1px #ccc;
	-moz-box-shadow: 3px 3px 5px 1px #ccc;
	box-shadow: 3px 3px 5px 1px #ccc;
	background-color: #c4c4c4;
}
.upper-row {
	padding: 50px;
	text-align: center;
	background: #ffff;
	border-radius: 10px;
}
.avatar img {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	margin-right: 10px;
}
.post-author {
	font-size: 20px;
	text-align: left;
}
.post-timestamp {
	text-align: left;
	font-size: 10px;
	margin-bottom: 20px;
}
.description {
	text-align: left;
	font-size: 12px;
}

/**
* Card content CSS
*/
.rounded {
	border-radius: 10px !important;
}

.rounded-image img {
	border-radius: 100%;
	width: 50px;
	height: 50px;
}

.h6 {
	font-size: 12px !important;
}

.h4 {
	font-size: 16px;
}

.h5 {
	font-size: 13px;
}

.badge img{
	width: 500px;
	height: auto;
}
</style>
